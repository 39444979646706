<template>
  <div class="crown">
    <img class="crown__house--background" :src="imgView" alt="background" />
    <div class="crown__img">
      <img :src="imgView" alt="" />
    </div>
    <Menu />
    <div class="crown__house--block">
      <div v-if="showCoookie">
        <div class="container__form">
          <div class="crown__house--block-header">
            <div class="crown__house--side">
              <div class="crown__house--side-home">
                <div class="crown__house--side-homename">strandkai</div>
                <div class="crown__house--side-homeside">&nbsp;</div>
                <div class="crown__house--side-homepage" v-on:click="cookies()">
                  Akzeptieren und schließen &#9660;
                </div>
              </div>
            </div>
          </div>
          <div class="outerContainerLogin">
            <div class="formContainer">
              <div class="formHeadline">
                <h2 v-if="cookieDetails == true">Cookie-Einstellungen</h2>
                <p v-if="cookieDetails == true">
                  Auf unserer Webseite werden Cookies eingesetzt. Einige von
                  ihnen sind notwendig und gewährleisten<br />
                  die Funktionalität der Webseite, andere helfen uns, die
                  Webseite und die Nutzerfahrung zu verbessern.<br />
                  Sie können entscheiden, welche Sie zulassen oder ablehnen
                  möchten.
                </p>
                <h2 v-if="cookieDetails == false">Cookie-Details</h2>
                <p v-if="cookieDetails == false">
                  Wir möchten, dass Ihr Zugriff auf unsere Dienste so einfach, effizient und nützlich wie möglich ist. Um <br />
                  uns dabei zu helfen, verwenden wir Cookies und ähnliche Technologien, um Ihr Erlebnis zu verbessern,<br />
                  die Sicherheit der Website zu erhöhen und Ihnen relevante Werbung zu zeigen.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="crown__house--block">
          <div class="crown__house--side">
            <div class="crown__house--side-home">
              <div class="crown__house--side-homename cookie-details" v-if="cookieDetails == true" v-on:click="cookieDetails = false">
                Cookie-Details
              </div>
              <div class="crown__house--side-homename cookie-details" v-if="cookieDetails == false" v-on:click="cookieDetails = true">
                Cookie-Einstellungen
              </div>
              <div class="crown__house--side-homeside">&nbsp;</div>
              <div>&nbsp;</div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!showCoookie" class="crown__house--side">
        <div class="crown__house--side-home">
          <div class="crown__house--side-homename">strandkai</div>
          <div class="crown__house--side-homeside">
            <button
              v-on:click="
                imgView = img.Ost;
                active = true;
              "
              class="side-button pointer"
              :class="active ? 'side-button-active' : ''"
            >
              Ansicht: Süd-Ost
            </button>
            &nbsp; | &nbsp;
            <button
              v-on:click="
                imgView = img.Nord;
                active = false;
              "
              class="side-button pointer"
              :class="!active ? 'side-button-active' : ''"
            >
              Ansicht: Nord-West
            </button>
          </div>
          <div class="crown__house--side-nameright">strandkai</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from './../components/Menu'
import axios from 'axios'

export default {
  data: function () {
    return {
      active: true,
      imgView: '',
      img: {
        Ost: '',
        Nord: ''
      },
      imgFB: [],
      cookie: '',
      showCoookie: true,
      cookieDetails: true
    };
  },
  name: 'House',
  props: {
    msg: String
  },
  components: {
    Menu
  },
  methods: {
    cookies() {
      localStorage.setItem('crownCookieDetails', '1');
      this.showCoookie = false
    }
  },
  mounted() {
    this.cookie = localStorage.getItem('crownCookieDetails')
    if (this.cookie == '0' || this.cookie == null || this.cookie == undefined || this.cookie == '')
      this.showCoookie = true
    if (this.cookie == '1')
      this.showCoookie = false

    axios.get('https://cadman-63dc7.firebaseio.com/Houses/f336c1a5-8cf2-4141-9509-51ab08eddfb0/Data/.json')
      .then((response) => {
        this.imgFB = response.data
      })
      .finally(() => {
        this.img.Ost = this.imgFB.sudOstImageUrl
        this.img.Nord = this.imgFB.nordWestImageUrl
        this.imgView = this.img.Ost
      })
  }
}
</script>

<style scoped>
button {
  padding: 0;
  margin: 1em;
  border: 0 solid #fff;
  background-color: rgba(255, 255, 255, 0);
  text-shadow: 1px 1px 1px #323a41;
}
.crown__house--background {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -100;
  filter: blur(10px);
}
.crown__img {
  position: fixed;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.crown__img img {
  height: 100vh;
}
.crown__house--block {
  position: fixed;
  width: 100%;
  bottom: 2em;
}
.crown__house--side {
  display: flex;
  justify-content: center;
  bottom: 2em;
  margin: 0 auto;
  width: 100%;
}
.crown__house--side .crown__house--side-home {
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  bottom: 2em;
  width: 1178px;
  justify-content: space-between;
  font-family: "35-FTR", sans-serif;
  font-size: 15px;
  letter-spacing: 0.06rem;
  box-sizing: border-box;
  height: 35px;
  align-items: center;
}
.crown__house--side-homeside button {
  margin: 0em 0.5em 0em 0.5em;
  text-shadow: 0 0;
}
.crown__houses--side-homeside button:focus {
  outline: none;
  border-bottom: 1px solid #323a41;
}
.crown__house--side-homename {
  text-transform: uppercase;
  font-weight: bold;
  padding-left: 2em;
}

.pointer {
  cursor: pointer;
}
.outline-none {
  outline: none;
}

button.side-button {
  font-weight: 300;
}

button.side-button.side-button-active {
  font-weight: 600;
}

button.side-button:focus,
button.side-button:active {
  outline: none;
}
button.side-button::-moz-focus-inner {
  border: 0;
}

.crown__house--block {
  position: fixed;
  width: 100%;
}
.crown__house--block-bottom {
  position: fixed;
  width: 1178px;
  bottom: 2em;
  z-index: 2;
  background: #fff;
  height: 35px;
}
.crown__house--side .crown__house--side-home {
  display: flex;
  flex-wrap: wrap;
  width: 1178px;
  justify-content: space-between;
  font-family: "35-FTR", sans-serif;
  font-size: 15px;
  letter-spacing: 0.06rem;
  box-sizing: border-box;
  height: 35px;
  align-items: center;
    justify-content: space-between;
}

.crown__house--side-homename {
  text-transform: uppercase;
  font-weight: bold;
  padding-left: 2em;
}
.crown__house--side-homepage {
  padding-right: 2em;
  cursor: pointer;
}
.crown__house--side-homepage a {
  color: #323a41;
  font-weight: 300;
}

.outerContainerLogin {
  margin: 0 auto;
}

.outerContainerLogin h2 {
  font-family: "35-FTR", sans-serif;
  font-size: 24px;
}

.outerContainerLogin p {
  font-size: 18px;
}

.formContainer {
  width: 1178px;
  position: relative;
  height: auto;
  display: block;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.8);
  padding-bottom: 3em;
  overflow-y: auto;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}

.formHeadline {
  max-width: 80%;
  margin: 0 auto;
  text-align: center;
}
p {
  margin: 0;
  padding: 0;
  font-size: 0.8em;
  padding-bottom: 0.2em;
  color: #323a41;
}
.formContainer .formHeadline p {
  margin: 0;
  padding: 0;
  padding-bottom: 0.5em;
  color: #323a41;
}
.formContainer .formHeadline p.headline {
  font-size: 1.2em;
  padding-bottom: 1.5em;
  color: #323a41;
}

.cookie-details {
  text-transform: capitalize;
}

.crown__house--side-nameright {
  text-transform: uppercase;
  font-weight: bold;
  padding-right: 2em;
  color: #fff;
}

.formHeadline h2 {
  margin-block-start: 0.5em;
  margin-block-end: 1.5em;
  font-size: 24px;
}

.cookie-details {
  cursor: pointer;
}
</style>
